import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

import { Link } from "gatsby";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  "Juridisk meddelelse": "/da/juridisk-meddelelse"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/legal-notice"
);

const LegalNotice = props => {
  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Juridiske Meddelelser"
          description="Juridiske meddelelser om Matthias Kupperschmidts tjenester inden for online marketing; dette inkluderer søgemaskineoptimering, webanalyse implementeringer, rådgivning om online marketing"
          lang="da"
          alternateLangs={alternateLangs}
        />
        <MainContent
          article={false}

        >
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

          <H as="h1">Juridiske Meddelelser</H>

          <p>Matthias Kupperschmidt tilbyder forskellige tjenester (kaldet “tjenester” nedenfor) inden for online marketing; dette inkluderer søgemaskineoptimering, webanalyse implementeringer, rådgivning om online marketing emner samt kurser og workshops.</p>

          <p>Brugen af de leverede tjenester er kun tilladt til det tilsigtede formål beskrevet i disse Generelle Vilkår og Betingelser og/eller ordren. Derudover er enhver brug eller behandling til andre formål kun tilladt under de strenge betingelser i den danske databeskyttelseslov, især Databeskyttelsesloven og EU's Generelle Databeskyttelsesforordning (GDPR). Enhver uberettiget brug eller udnyttelse er ikke tilladt. Især er den fuldstændige brug af de leverede tjenester og indhold til kommercielle formål af enhver art og i enhver medieform (trykt, elektronisk, på CD osv.) samt udlæsning af registre, databaser osv., til de nævnte formål eller til formål med enhver anden kommerciel brug ikke tilladt og vil blive retsforfulgt af udbyderen i henhold til gældende lovgivning ved at udtømme alle tilgængelige juridiske midler.</p>
          <p>Tilgængeligheden af individuelle tjenester samt rigtigheden og fuldstændigheden af indholdet/optegnelserne/dataene garanteres ikke.</p>
          <p>Disse Vilkår og Betingelser regulerer forholdet mellem Matthias Kupperschmidt og hans kunder og kontraktpartnere (kaldet “kontraktpartnere” nedenfor)</p>

          <p>Afsnit 1 Omfang</p>
          <p>Matthias Kupperschmidts tjenester udføres udelukkende på grundlag af disse Generelle Vilkår og Betingelser. De gælder derfor også for alle fremtidige forretningsforhold, selvom de ikke udtrykkeligt aftales igen.</p>
          <p>Brugen af de tjenester, der tilbydes af Matthias Kupperschmidt til kontraktpartneren, er udelukkende underlagt disse Generelle Vilkår og Betingelser (GTC) samt de særlige vilkår og betingelser for de respektive produkter/tjenester. For tilbud, tjenester, individuelle projekter, kampagner og andre tjenester, især projekttjenester udover dette, gælder de betingelser, der skal aftales i separate aftaler.</p>
          <p>Kontraktpartnerens eller andre tredjeparts generelle vilkår og betingelser gælder ikke, selvom Matthias Kupperschmidt ikke udtrykkeligt gør indsigelse mod dem og/eller leverer sine tjenester uden indsigelse.</p>

          <p>Afsnit 2 Generelt omfang af tjenester</p>
          <p>Blandt andet er Matthias Kupperschmidt aktiv inden for søgemaskineoptimering, webanalyse, inbound marketing, rådgivning om online marketing emner samt kurser og workshops. Den detaljerede beskrivelse af den bestilte tjeneste kan ses i den respektive ordre.</p>
          <p>De tjenester og det indhold, der tilbydes af Matthias Kupperschmidt, leveres og offentliggøres af Matthias Kupperschmidt, medmindre andet er angivet. Ethvert indhold, der gøres tilgængeligt af tredjeparter, er information leveret af den respektive skaber, forfatter eller distributør og ikke information leveret af Matthias Kupperschmidt. Matthias Kupperschmidt er derfor ikke ansvarlig for nøjagtigheden eller pålideligheden af dette indhold.</p>
          <p>De tjenester, der tilbydes af Matthias Kupperschmidt, er kun en invitation til at indsende et forslag om at indgå en kontrakt. Kontraktpartneren er bundet af sin erklæring i ti arbejdsdage. Bekræftelse i tekstform (e-mail er tilstrækkelig) af Matthias Kupperschmidt er påkrævet for indgåelse af en kontrakt. En forretningskontrakt indgås derefter på grundlag af den gældende GTC.</p>
          <p>Matthias Kupperschmidt er ikke forpligtet til at indgå kontrakter. Han er berettiget til at afvise indgåelse af kontrakter uden at give grunde. Efterfølgende ændringer og tilføjelser til kontrakter kræver skriftlig bekræftelse af Matthias Kupperschmidt. Separate aftaler skal indgås af kontraktpartnerne for yderligere tjenester.</p>
          <p>Matthias Kupperschmidt forbeholder sig også retten til senere at udelukke kontraktpartnere fra brugen af tjenesterne, hvis der er bevis for, at kontraktpartneren misbruger tjenesterne eller bruger dem på en måde, der kan skade Matthias Kupperschmidts omdømme. Dette gælder især, hvis kontraktpartneren bryder pligter fra kontraktforholdet eller skader andre kontraktpartnere hos Matthias Kupperschmidt.</p>
          <p>For så vidt en kontraktpartner bruger software fra den respektive anden kontraktpartner som en del af brugen af tjenesterne, giver den respektive anden kontraktpartner ham – medmindre andet er udtrykkeligt reguleret – en ikke-eksklusiv, ikke-overførbar brugsret, der er tidsbegrænset til kontraktens varighed og, hvad angår indhold, begrænset til brug i henhold til tjenestens levering. Yderligere brugsrettigheder gives ikke til kontraktpartneren.</p>
          <p>Til vedligeholdelsesformål og for at udføre reparationer er Matthias Kupperschmidt berettiget til at afbryde levering af tjenester eller dele heraf i det omfang, det er rimeligt for kontraktpartneren. Generelt vil Matthias Kupperschmidt bestræbe sig på at udføre vedligeholdelses- og reparationsarbejde på tidspunkter, hvor belastningen af det vil være så lav som muligt. Dette etablerer ikke nogen erstatningskrav fra kontraktpartnerens side, medmindre Matthias Kupperschmidt er skyld i det.</p>
          <p>Matthias Kupperschmidt er berettiget til at indgå lignende kontraktforhold med konkurrenter til kontraktpartneren. Matthias Kupperschmidt vil dog bestræbe sig på – uden at være forpligtet til det – at beskytte de berettigede interesser hos kontraktpartneren i denne henseende og undgå interessekonflikter. Matthias Kupperschmidt er berettiget til at levere delvise tjenester såvel som tjenester fra tredjeparter.</p>
          <p>Deadlines og datoer anmodet af kontraktpartneren er kun bindende, når Matthias Kupperschmidt udtrykkeligt har bekræftet dem skriftligt, og hvis kontraktpartneren i rette tid har skabt alle de nødvendige betingelser for levering af tjenesten, der ligger inden for hans indflydelsessfære.</p>
          <p>For så vidt og så længe kontraktpartneren ikke har opfyldt nogen af sine samarbejdsforpligtelser, der kræves for levering af tjenesten, selv efter en skriftlig anmodning fra Matthias Kupperschmidt og fastsættelse af en rimelig frist, vil Matthias Kupperschmidt være fritaget for at opfylde den forpligtelse, der er påvirket af denne undladelse på den deadline, der er aftalt i projektkontrakten. Deadlinen aftalt i ordren/kontrakten for levering skal forlænges tilsvarende.</p>


          <p>Afsnit 3 Kontrahering, kontraktens omfang</p>
          <p>Hver tjeneste skal bestilles separat af kontraktpartneren (individuel ordre/tjenesteniveauaftale). Kontrahenterne skal gensidigt give hinanden det indhold, der kræves for at opfylde ordren. Hvis sporingskoder er integreret i dette indhold, må de kun ændres eller slettes af den respektive anden kontraktpartner efter forudgående konsultation og aftale.</p>
          <p>Matthias Kupperschmidt skal oprette en faktura til kontraktpartneren for hver udført kampagne, der tydeligt viser alle de oplysninger, der er specificeret i punkt 1.</p>

          <p>Afsnit 4 Forpligtelser fra kontraktpartneren</p>
          <p>Klienten forpligter sig til at støtte de foranstaltninger, der træffes af Matthias Kupperschmidt. Klienten skal give Matthias Kupperschmidt alle nødvendige oplysninger og data, der kræves for at opfylde ordren.</p>
          <p>Kontraktpartneren forpligter sig til at sikre, at det indhold, han leverer og er forbundet med brugen af Matthias Kupperschmidts tjenester, ikke krænker gældende lov i staten Danmark; dette gælder især for ulovligt, umoralsk eller pornografisk indhold eller sådant indhold, der forherliger krig eller er egnet til at udgøre en moralsk risiko for børn og unge eller negativt påvirke deres trivsel.</p>
          <p>Kontraktpartneren skal også rapportere alle hændelser og omstændigheder, der kan være af betydning for korrekt og rettidig udførelse af kontrakten. Dette gælder også for hændelser og omstændigheder, kontraktpartneren først bliver opmærksom på under kontraktens løbetid. Projektforsinkelser på grund af, at kontraktpartneren ikke opfylder nødvendige samarbejdsforpligtelser eller ikke opfylder dem rettidigt, er på kontraktpartnerens regning og påvirker ikke Matthias Kupperschmidts betalingskrav.</p>
          <p>Enhver overtrædelse af den forpligtelse, der er angivet i punkt 1, giver Matthias Kupperschmidt ret til straks at opsige kontrakten mellem Matthias Kupperschmidt og kontraktpartneren. Matthias Kupperschmidt forbeholder sig retten til straks at opsige selvom der kun er en mistanke om, at kontraktpartneren spreder eller tolererer indhold som beskrevet i punkt 1. Matthias Kupperschmidt er berettiget, men ikke forpligtet, til at overvåge lovligheden af indholdet.</p>
          <p>Kontraktpartneren forpligter sig til nøje at overholde de tekniske og indholdsmæssige specifikationer, der kræves af Matthias Kupperschmidt. Enhver forsinkelse og/eller ændring af de tjenester, der leveres af Matthias Kupperschmidt som følge af manglende overholdelse af disse specifikationer, er på kontraktpartnerens regning og medfører ikke krav mod Matthias Kupperschmidt.</p>
          <p>Desuden er kontraktpartneren ansvarlig for, at alle oplysninger og dokumenter, der kræves for at opfylde hans kontraktlige forpligtelser og den korrekte gennemførelse af samarbejdet, stilles til rådighed for Matthias Kupperschmidt i rette tid, fuldstændigt og i en form, der er egnet til opfyldelse før den aftalte implementering af kontrakten.</p>
          <p>Hvis en part er forhindret i at levere den kontraktlige tjeneste og/eller indhold på grund af omstændigheder, som den ikke er ansvarlig for, skal denne part informere den respektive anden part og straks overføre tilpasset indhold og/eller tjenester til omstændighederne. Hvis de nævnte omstændigheder varer længere end 24 timer, skal parterne nå til enighed om, hvordan de skal fortsætte.</p>
          <p>Kontraktpartneren er ikke berettiget til at bruge, reproducere, distribuere, offentliggøre, ændre eller på anden måde udnytte Matthias Kupperschmidts, hans kunders og partneres varemærker og/eller logoer, medmindre Matthias Kupperschmidt udtrykkeligt har givet sit samtykke skriftligt på forhånd. Desuden er kontraktpartneren forpligtet til at respektere tredjeparts ophavsrettigheder eller varemærkerettigheder, både danske og udenlandske.</p>
          <p>Kontraktpartneren accepterer videresendelse af hans indhold, data og andre oplysninger til tredjeparter i det omfang, det er nødvendigt og påkrævet for opfyldelsen af det kontraktlige forhold mellem parterne.</p>

          <p>Afsnit 5 Kontraktens varighed/opsigelse</p>
          <p>Kostestimater af Matthias Kupperschmidt er ikke bindende. Matthias Kupperschmidt forpligter sig til straks at informere kontraktpartneren om enhver afvigelse i de faktiske omkostninger i forhold til det anslåede beløb, hvis afvigelsen er mere end ti procent.</p>
          <p>Kontrakten, der er indgået mellem Matthias Kupperschmidt og kontraktpartneren, slutter ved udløbet af den aftalte periode. For resten kan den respektive kontraktperiode ses i tilbuddet.</p>
          <p>Hvis der er aftalt en minimumsperiode med kontraktpartneren, forlænges aftalen automatisk med den aftalte periode, medmindre forlængelsen modsiges senest 30 dage før udløbet af den relevante kontraktperiode.</p>
          <p>Kontrakter, der er indgået for en ubestemt periode, kan opsiges skriftligt af begge parter til enhver tid med et varsel på tre måneder til månedens udgang.</p>
          <p>Retten til at opsige kontrakten for en god grund forbliver upåvirket. En god grund foreligger især i tilfælde af, at en af parterne overtræder væsentlige forpligtelser og pligter, der følger af kontrakten eller gældende lov i staten Danmark og ikke genopretter den kontraktmæssige tilstand trods en skriftlig advarsel fra den anden part inden for 7 dage efter modtagelse af advarslen. Advarslen skal specificere en overtrædelse af en forpligtelse eller pligt og påpege mulighederne for opsigelse af en god grund. Væsentlige kontraktlige forpligtelser er især forpligtelser til at opretholde fortrolighed.</p>

          <p>For Matthias Kupperschmidt foreligger en god grund, hvis</p>
          <p>åbningen af insolvens- eller konkursbehandling mod kontraktpartnerens aktiver er blevet indgivet</p>
          <ol>
            <li>en anden culpabel overtrædelse af væsentlige kontraktlige forpligtelser eller pligter fra en kontraktpartner, hvis de ikke straks er elimineret trods en advarsel.</li>
          </ol>
          <p>Indtil opsigelsen træder i kraft, bevarer hver kontraktpartner sit krav på eventuelle gebyrer og provisioner, der stadig vil blive påløbet. Indtil dette tidspunkt er kontraktpartnerne også forpligtet til at opfylde deres kontraktlige forpligtelser. Enhver forpligtelse, der går ud over kontraktperioden, især dem, der vedrører databeskyttelse og fortrolighed, forbliver upåvirket af dette.</p>
          <p>Alle opsigelser skal ske skriftligt eller via e-mail.</p>

          <p>Afsnit 6 Vederlag og forfaldsdatoer</p>
          <p>Det aftalte vederlag forfalder ved afslutningen af rådgivningen og/eller implementeringen af foranstaltninger. En accept er ikke påkrævet.</p>
          <p>Matthias Kupperschmidt er berettiget til en passende betaling allerede før afslutningen af rådgivningen og implementeringen af foranstaltningerne. I tvivlstilfælde baseres tilstrækkeligheden på den allerede leverede arbejdstid. I tilfælde af permanent rådgivning er Matthias Kupperschmidt berettiget til at fakturere tjenester straks efter, at de er blevet leveret. Vederlaget baseres på tilbuddet fra Matthias Kupperschmidt.</p>

          <p>Afsnit 7 Pengeoverførsel, betalingsbetingelser</p>
          <p>Vederlaget eller fordelingen af salget sker altid i henhold til den opdeling, der er specificeret i den individuelle ordre.</p>
          <p>Medmindre andet er aftalt i den individuelle ordre, foretages afregning af Matthias Kupperschmidt månedligt. Alle salgstal og gebyrer er nettobeløb; moms vises separat.</p>
          <p>Matthias Kupperschmidt er berettiget til at kræve forskud på op til 100% af ordremængden; forskud forfalder ved underskrivelse af kontrakten.</p>
          <p>I tilfælde af forsinket betaling er Matthias Kupperschmidt berettiget til at opkræve misligholdelsesrenter til kontraktpartneren – samtidig med forbehold af retten til at kræve yderligere skadesrenter – med fem procentpoint over den respektive basisrente. Misligholdelsesrenten er lavere, hvis kontraktpartneren beviser en lavere skade.</p>
          <p>Kontraktpartneren har kun ret til tilbageholdelse eller ret til modregning, for så vidt som kontraktpartnerens krav er ubestridte og lovligt fastslået.</p>


          <p>Afsnit 8 Ejendomsrettigheder og ophavsretlig beskyttelse, brugsrettigheder</p>
          <p>Ophavsrettighederne og andre ejendomsrettigheder til tjenesterne leveret af Matthias Kupperschmidt forbliver hos Matthias Kupperschmidt.</p>
          <p>Matthias Kupperschmidt giver kontraktpartneren ikke-eksklusive, tidsbegrænsede og territorialt ubegrænsede brugsrettigheder til udnyttelse af den komplette tjeneste, der er leveret som aftalt (arbejdsresultater).</p>
          <p>Kontraktpartneren er dog ikke berettiget til at sælge det leverede indhold og information til tredjeparter eller stille dem til rådighed for tredjeparter uden for deres tilsigtede formål.</p>
          <p>Ændringer af de tjenester, der leveres af Matthias Kupperschmidt, der skal udføres af kontraktpartneren eller en tredjepart, der er opdraget af kontraktpartneren, er kun tilladt med Matthias Kupperschmidts udtrykkelige samtykke.</p>
          <p>Udøvelsen af brugsrettighederne er kun tilladt, hvis kontraktpartneren ikke er i restance med en betaling på det pågældende tidspunkt.</p>
          <p>På grundlag af aftaler med medarbejdere, agenter og eventuelle tredjeparts rettighedshavere sikrer Matthias Kupperschmidt, at kontraktpartneren frit kan udøve de kontraktlige brugsrettigheder.</p>

          <p>Afsnit 9 Garanti og ansvar</p>
          <p>Matthias Kupperschmidt skal drive sine tjenester inden for rammerne af sine tekniske muligheder og påtager sig ikke nogen garanti eller garanti for uafbrudt og/eller konstant fejlfri tilgængelighed af sine tjenester; især ikke for linjeudfald og/eller forbindelsesfejl, hardware- og/eller softwarefejl eller for handlinger fra tredjeparter (f.eks. virus eller “denial of service” angreb). Kontrahenterne er enige om, at forekomsten af sådanne afbrydelser og/eller fejl ikke etablerer erstatningskrav af nogen art.</p>
          <p>Defekter er afvigelser fra tjenestebeskrivelsen, der begrænser den kontraktmæssige brug. Eventuelle defekter skal rapporteres detaljeret til Matthias Kupperschmidt. Parterne skal samarbejde i defektanalyse og fejlfinding. Udbedring udføres inden for rammerne af ansvar for defekter og medfører ingen omkostninger for kontraktpartneren.</p>
          <p>Kontraktpartneren skal give Matthias Kupperschmidt en passende periode til udbedring. Hvis udbedringen ikke lykkes, selv inden for en anden passende frist (mindst dog inden for 15 arbejdsdage), er kontraktpartneren berettiget til at kræve en reduktion i betalingen eller kompensation for skade på grund af manglende opfyldelse, hvis han har meddelt et sådant skridt, når han har fastsat fristen.</p>
          <p>Defektkrav fra kontraktpartnerens side kan ikke længere gøres gældende, hvis han har udført ændringer af tjenesterne selv eller har fået sådanne ændringer udført af tredjeparter uden forudgående skriftligt samtykke, medmindre kontraktpartneren kan bevise, at defekterne ikke skyldes disse ændringer, eller at disse ændringer ikke hindrer defektanalysen og fjernelsen eller kun gør det marginalt.</p>
          <p>Defektkrav er principielt udelukket, hvis kontraktpartneren bruger tjenesterne under brugsbetingelser, der afviger fra dem, der er specificeret i aftalen. Det er kontraktpartnerens ansvar at bevise, at defekten ikke skyldtes en sådan afvigelse.</p>
          <p>Krav er udelukket for skade i henhold til den danske købelov på grund af mangler i begyndelsen af kontrakten, som Matthias Kupperschmidt ikke er ansvarlig for, samt på grund af kun uagtsomme mangler.</p>
          <p>Defektkrav udløber et år efter accept; i tilfælde af tjenester, der ikke er egnede eller ikke har brug for accept, et år efter levering/overtagelse eller efter meddelelse om færdiggørelse og/eller levering af tjenesten.</p>
          <p>Matthias Kupperschmidt er ikke ansvarlig for tekniske fejl, hvis årsager ikke ligger inden for Matthias Kupperschmidts ansvarsområde eller for skade forårsaget af force majeure. I undtagelsestilfælde kan det forekomme, at individuelle data, tjenester eller andre tekniske eller faktiske aktiviteter ikke kan fanges eller korrekt faktureres af Matthias Kupperschmidt. Ethvert krav baseret på dette – især krav om skade eller værdiudskiftning – mod Matthias Kupperschmidt fra kontraktpartnerens side er udelukket.</p>
          <p>Matthias Kupperschmidt er kun ansvarlig for skade på grund af overtrædelse af væsentlige kontraktlige forpligtelser eller på grund af fravær af sikrede egenskaber og egenskaber. Udover det er Matthias Kupperschmidt kun ansvarlig, hvis skaden var forårsaget af grov uagtsomhed eller forsætlig hensigt.</p>
          <p>Matthias Kupperschmidt er ansvarlig i tilfælde af en let uagtsom overtrædelse af en væsentlig kontraktlig forpligtelse og, i tilfælde af ikke-kontraktligt ansvar, kun i tilfælde af tab af liv, fysisk skade og sundhedsskade, i tilfælde af overtrædelse af garantier, væsentlige kontraktlige forpligtelser samt i overensstemmelse med produktansvarsloven kun i det omfang, der gælder for typisk skade, der var forudsigelig, da kontrakten blev underskrevet. Skaden beregnes på grundlag af den gennemsnitlige månedlige ordremængde inden for de sidste seks måneder. Især er ansvar for let uagtsom overtrædelse af væsentlige kontraktlige forpligtelser i tilfælde af tab af fortjeneste, indirekte skade og følgeskade principielt udelukket.</p>
          <p>Ansvar for følgeskade, indirekte skade og/eller tab af fortjeneste er udelukket, medmindre i tilfælde af forsætlig adfærd. Mængdemæssigt er ansvaret begrænset til den forventede normale skade i tilfælde af tab inden for Danmark; maksimalt dog til det samlede vederlag, der skal betales af kontraktpartneren i henhold til den respektive ordre. Denne ansvarsbegrænsning gælder på samme måde for skade forårsaget af grov uagtsomhed eller forsæt på medarbejdernes eller agenternes side af Matthias Kupperschmidt.</p>
          <p>I tilfælde af, at kontraktpartneren har foretaget ændringer af den kontraktlige tjeneste eller dens miljø eller har fået sådanne ændringer udført af tredjeparter, udløber alle krav fra kontraktpartneren fra de ovenstående bestemmelser, medmindre defekten ikke er forårsaget, helt eller delvist, af de ændringer, der er udført af kontraktpartneren, og udbedring ikke gøres vanskeligere af ændringerne.</p>
          <p>Disse ansvarsbegrænsninger gælder mutatis mutandis for alle medarbejdere og agenter hos Matthias Kupperschmidt. Ethvert ansvar fra Matthias Kupperschmidt i henhold til produktansvarsloven forbliver upåvirket.</p>
          <p>Alle krav om skade eller andre krav, der kunne rettes mod Matthias Kupperschmidt på grund af egenskaber og tilstand eller defekter ved tjenesten, falder under forældelsesfristen på 6 (i ord: seks) måneder efter afslutningen af den respektive kontrakt.</p>
          <p>Kontraktpartneren er ansvarlig over for Matthias Kupperschmidt for indhold, dokumenter, links og andre materialer (kaldet “dokumenter” nedenfor), der er leveret af ham og stillet til rådighed for Matthias Kupperschmidt til implementering af samarbejdsprojektet.</p>
          <p>Hvis det kræves, skal kontraktpartneren på egen regning kontrollere, om de tjenester, der leveres af Matthias Kupperschmidt, overholder relevante lovbestemmelser. Matthias Kupperschmidt vil kun iværksætte en sådan ekstern juridisk kontrol efter udtrykkelig skriftlig anmodning fra kontraktpartneren; de tilknyttede omkostninger bæres af kontraktpartneren.</p>
          <p>I tilfælde af, at tredjeparter gør krav gældende mod Matthias Kupperschmidt på grund af en overtrædelse af ophavsrettigheder og/eller overtrædelse af relevante lovbestemmelser, der er opstået inden for kontraktpartnerens pligter, skal kontraktpartneren gøre alt for at forsvare Matthias Kupperschmidt mod de hævdede krav eller rettigheder og eliminere den påståede overtrædelse. Kontraktpartneren er også ansvarlig over for Matthias Kupperschmidt for enhver skade forårsaget af tredjeparter, der opstod som følge af, at ordren blev udført delvist, fejlbehæftet, forsinket, sent eller slet ikke. Det samme gælder for enhver agent af kontraktpartneren. Kontraktpartneren skal bære bevisbyrden for korrekt udførelse af ordren.</p>
          <p>Kontraktpartneren frigør Matthias Kupperschmidt fra alle krav om skade, erstatningskrav og omkostninger, som Matthias Kupperschmidt pådrager sig som følge af, at der rettes krav mod det, ifølge hvilke kontraktpartnerens dokumenter overtræder konkurrencelovgivningen, krænker tredjeparts industrielle ejendomsrettigheder eller andre love og bestemmelser. Især frigør kontraktpartneren Matthias Kupperschmidt, på første anmodning, straks fra enhver krav fra tredjeparter, der rettes mod Matthias Kupperschmidt på grund af det anvendte indhold eller et tilbud, der er linket via hyperlink, især under den danske Databeskyttelseslov (DDPA) og EU's Generelle Databeskyttelsesforordning (GDPR), og refunderer, på anmodning, enhver omkostning, Matthias Kupperschmidt pådrager sig på grund af passende juridiske handlinger.</p>

          <p>Afsnit 10 Lovligheden af websteder</p>
          <p>morefire kontrollerer ikke, om indholdet af kundens websteder krænker tredjeparters rettigheder eller er oprettet i overensstemmelse med retningslinjerne for de enkelte søgemaskineoperatører. Kunden alene er ansvarlig for enhver straf fra tredjeparter.</p>
          <p>Kunden er også ansvarlig for den juridiske lovlighed af indholdet af sine websteder samt for de oplysninger, der leveres af ham, herunder søgeord eller nøgleord; det samme gælder for beskyttelsen af tredjeparters rettigheder, især med hensyn til ophavsret, konkurrencelovgivning og strafferet.</p>

          <p>Afsnit 11 Servicekontrakt</p>
          <p>Medarbejdere, der er ansat inden for rammerne af servicekontrakter, må ikke pocheres af kunden inden for en periode på 24 måneder efter tildeling af kontrakten. Hvis en sådan pocheri finder sted inden for denne periode, er entreprenøren berettiget til en engangsbetaling på 75.000 €.</p>

          <p>Afsnit 12 Databeskyttelse</p>
          <p>Uden en yderligere erklæring om samtykke indsamles, behandles og anvendes personoplysninger – medmindre andet er angivet – kun med henblik på at levere tjenesterne, brugen af tjenesterne og, hvis relevant, til fakturering.</p>
          <p>Kontraktpartneren er forpligtet til at overholde alle gældende databeskyttelsesbestemmelser, især bestemmelserne i den danske Databeskyttelseslov (DDPA) og EU's Generelle Databeskyttelsesforordning (GDPR).</p>
          <p>Kontraktpartneren garanterer at bruge data, han måtte have indsamlet, udelukkende til det angivne formål samt til markedsundersøgelser og reklameformål inden for virksomheden, i det omfang det er nødvendigt for udførelsen af de indgåede transaktioner og opretholdelsen af det resulterende brugerforhold, og hvis det er tilladt ved lov og ønsket af brugeren. Videreformidling af data til tredjeparter fra kontraktpartnerens side er udelukket, og anklager vil blive rejst.</p>

          <p>Afsnit 13 Fortrolighed og hemmeligholdelse</p>
          <p>Kontraktpartneren er forpligtet til at bruge de dokumenter, den viden og de erfaringer, der er erhvervet fra Matthias Kupperschmidt, udelukkende til formålene med denne kontrakt. Desuden aftaler kontraktparterne fortrolighed med hensyn til tredjeparter vedrørende indholdet af de indgåede aftaler samt enhver viden, der er opnået under deres gennemførelse. Kontraktpartneren er forpligtet til at rådføre sig med Matthias Kupperschmidt, hvis der opstår tvivl om, hvorvidt oplysninger i en bestemt individuel sag skal behandles fortroligt.</p>
          <p>I tilfælde af en mulig underentreprise skal kontraktpartneren forpligte den respektive underleverandør til forpligtelser inden for denne bestemmelse.</p>
          <p>Kontraktpartneren skal sikre overholdelse af denne fortrolighedsforpligtelse med hensyn til sine medarbejdere og enhver tredjepart, der er involveret i kontraktens gennemførelse, og som har adgang til de dokumenter og objekter, der er specificeret i første afsnit.</p>


          <p>Afsnit 14 Ændringer</p>
          <p>Matthias Kupperschmidt skal levere sine tjenester i overensstemmelse med de respektive produktbeskrivelser. Matthias Kupperschmidt forbeholder sig ret til at ændre, udvide eller tilpasse tjenesterne, som helhed eller delvist, eller at ændre, udvide eller tilpasse typen og indholdet af individuelle produkter og tjenester, der tilbydes inden for rammerne af tjenesteydelserne til enhver tid og gentagne gange for at forbedre tilbuddet, især dets funktioner, design og tekniske udvikling. Kontraktpartneren skal informeres om større ændringer af produkterne via e-mail rettidigt. Den nye version af produktbeskrivelserne skal vedhæftes. Hvis kontraktpartneren ikke er enig i ændringen, har han ret til at opsige kontrakten med Matthias Kupperschmidt med øjeblikkelig virkning. Hvis kontraktpartneren ikke gør indsigelse mod ændringen inden for to uger, anses de anmeldte ændringer for at være de nye betingelser.</p>
          <p>Derudover er Matthias Kupperschmidt berettiget til at afbryde individuelle funktioner, der tilbydes som en del af tjenesterne – især i tilfælde af ændrede lovkrav, langvarige tab, tekniske vanskeligheder eller i tilfælde af misbrug af kunder, kontraktpartnere eller tredjeparter.</p>
          <p>Matthias Kupperschmidt forbeholder sig også udtrykkeligt ret til at foretage prisændringer, som Matthias Kupperschmidt skal informere kontraktpartneren om inden for en rimelig periode.</p>
          <p>Matthias Kupperschmidt forbeholder sig også ret til at ændre eller supplere disse GTC, især i tilfælde af, at nye tjenester introduceres eller ændringer er nødvendige på grund af lovbestemmelser. Matthias Kupperschmidt skal informere kontraktpartneren om ændringer eller tilføjelser til GTC. Hvis kontraktpartneren ikke er enig i ændringerne, kan han gøre indsigelse inden for to uger efter, at de blev meddelt ham skriftligt.</p>
          <p>I tilfælde af, at kontraktpartneren gør indsigelse mod ændringerne af tjenesterne, disse GTC eller de særlige vilkår og betingelser for de enkelte produkter, har Matthias Kupperschmidt ret til uden varsel at opsige kontrakter, der er indgået under disse GTC. Hvis kontraktpartneren ikke gør indsigelse, anses hans samtykke for givet ved udløbet af de nævnte perioder. Matthias Kupperschmidt skal påpege længden af perioden og betydningen af dens udløb i meddelelsen om ændringen til GTC.</p>

          <p>Afsnit 15 Afsluttende bestemmelser</p>
          <p>Afsendelse af meddelelser til Matthias Kupperschmidt via e-mail opfylder kun kravet om skriftlig form, hvis det udtrykkeligt er fastsat i disse brugsbetingelser, og hvis Matthias Kupperschmidt udtrykkeligt har accepteret det på forhånd.</p>
          <p>En overdragelse af kontraktlige rettigheder og forpligtelser fra kontraktpartneren til en tredjepart er kun tilladt med forudgående skriftligt samtykke fra Matthias Kupperschmidt.</p>
          <p>Kontraktpartneren har kun ret til tilbageholdelse i tilfælde af ubestridte eller lovligt fastlagte krav.</p>
          <p>Der er ingen kollaterale aftaler. Enhver aftale mellem parterne skal være skriftlig. Dette gælder også for annullering af kravet om skriftlig form.</p>
          <p>Kontraktpartneren skal informere Matthias Kupperschmidt rettidigt om forestående insolvensbehandlinger for at sikre opfyldelsen af de kontraktlige tjenester og forpligtelser eller for at muliggøre en samtykkebaseret opsigelse af kontrakten.</p>
          <p>Hvis individuelle bestemmelser i de Generelle Vilkår og Betingelser er ugyldige som helhed eller delvist, eller hvis de indeholder et hul, forbliver gyldigheden af de resterende bestemmelser eller dele af sådanne bestemmelser upåvirket af dette. I et sådant tilfælde er parterne forpligtet til at deltage i oprettelsen af sådanne nye bestemmelser, hvorved et kommercielt resultat opnås med juridisk effekt, der kommer tættest på den ugyldige eller ufuldstændige bestemmelse, samtidig med at de gensidige interesser opretholdes.</p>
          <p>Opfyldelsessted for begge parter er forretningsstedet for Matthias Kupperschmidt.</p>
          <p>Lovgivningen i staten Danmark gælder udelukkende med undtagelse af De Forenede Nationers konvention om kontrakter for det internationale salg af varer</p>
          <p>(CISG) og henvisninger til udenlandsk lovgivning.</p>
          <p>Hvis kunden er en købmand, en juridisk enhed under offentlig ret eller en særlig fond under offentlig ret, eller hvis han ikke har noget kontor i Danmark, er det eksklusive værneting København, Danmark. Matthias Kupperschmidt er også berettiget til at anlægge sag på forretningsstedet for kontraktpartneren.</p>

          <p>Afsnit 13 Tvistbilæggelse</p>
          <p>Europa-Kommissionen har oprettet en platform til online tvistbilæggelse. Den kan tilgås på: http://ec.europa.eu/consumers/odr/.</p>
          <p>Forbrugere kan bruge platformen til tvistbilæggelse. Matthias Kupperschmidt er ikke villig eller forpligtet til at deltage i tvistbilæggelsesprocedurer for en Forbrugervoldgiftsinstans, medmindre der er en lovpligtig forpligtelse til at deltage.</p>
          <p>København, 12. juni 2024</p>




        </MainContent>
      </React.Fragment>

    </Layout>
  );
};
export default LegalNotice;